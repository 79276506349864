<div class="relative bg-white dark:bg-night-700 shadow-md">
  <div class="mx-auto px-5">
    <div class="items-center justify-between py-3.5 md:justify-start">
      <!-- Mobile Navigation Menu Button-->
      <div class="sm:order-1 md:hidden">
        <button (click)="toggleMobileMenu()" type="button"
          class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 hover:bg-gray-100 hover:text-gray-500"
          aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          <svg-icon src="assets/icons/heroicons/outline/menu.svg" [svgClass]="'h-6 w-6'"> </svg-icon>
        </button>
      </div>

      <!-- Desktop Menu with margin -->
      <div class="hidden md:block mx-6 space-x-10 flex-grow">
        <app-header-menu></app-header-menu>
      </div>
    </div>
  </div>
  <!-- Mobile menu -->
  <app-header-mobile></app-header-mobile>
</div>