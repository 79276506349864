import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgFor, NgClass, NgTemplateOutlet, NgIf } from '@angular/common';
import { MenuService } from '../../../../services/menu/menu.service';
import { SubMenuItem } from '../../../../../models/menu.model';

@Component({
    selector: 'app-header-mobile-menu',
    templateUrl: './header-mobile-menu.component.html',
    styleUrls: ['./header-mobile-menu.component.scss'],
})
export class HeaderMobileMenuComponent implements OnInit {
  constructor(public menuService: MenuService) {}

  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }

  public closeMenu() {
    this.menuService.showMobileMenu = false;
  }

  ngOnInit(): void {}
}
