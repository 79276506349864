import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../../../models/menu.model';
import { MenuService } from '../../../services/menu/menu.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../authentication/services/authenticationService.service';
import { authUserModel } from '../../../../authentication/models/auth';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  private showMenuClass = ['scale-100', 'animate-fade-in-up', 'opacity-100', 'pointer-events-auto'];
  private hideMenuClass = ['scale-95', 'animate-fade-out-down', 'opacity-0', 'pointer-events-none'];

  public authUserInformation: authUserModel | null = this.getAuthUserInformation();

  constructor(
    public menuService: MenuService,
    private router: Router,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authUserInformation = this.getAuthUserInformation() as authUserModel | null;
  }

  public toggleMenu(menu: MenuItem): void {
    menu.selected = !menu.selected;
  }

  public getAuthUserInformation() {
    const authUserInformation = localStorage.getItem('authUserInformation');
    if (authUserInformation === null) {
      return null;
    }

    return JSON.parse(authUserInformation) as unknown as  authUserModel;
  }

  public mouseEnter(event: any): void {
    let element = event.target.querySelector('app-navbar-submenu').children[0];
    if (element) {
      this.hideMenuClass.forEach((c) => element.classList.remove(c));
      this.showMenuClass.forEach((c) => element.classList.add(c));
    }
  }

  public mouseLeave(event: any): void {
    let element = event.target.querySelector('app-navbar-submenu').children[0];
    if (element) {
      this.showMenuClass.forEach((c) => element.classList.remove(c));
      this.hideMenuClass.forEach((c) => element.classList.add(c));
    }
  }

  public navigateTo(route: string | null | undefined) {
    this.router.navigate([route]);
  }

  public logout() {
    this.authService.logout();
  }
}
