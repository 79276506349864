<nav [ngClass]="menuService.showSideBar ? 'w-72 xl:w-100' : 'w-[70px]'"
  class="z-10 scrollbar-thumb-rounded scrollbar-track-rounded hidden h-full flex-col justify-between overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:bg-night-700 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600 lg:flex fixed ">
  <div class="flex flex-col justify-between h-full bg-gray-200 custom-background">
    <div class="px-4">
      <div *ngIf="menuService.pagesMenu[0].shownewRequest " class="py-4 text-center">
        <button (click)="startSpecification()"
          class="bg-green-600 text-white px-14 py-1 rounded-full hover:bg-green-700">
          Nieuwe aanvraag
        </button>
      </div>
      <app-sidebar-menu (menuItemPressed)="handleMenuItemPressed($event)"></app-sidebar-menu>
    </div>
  </div>
</nav>