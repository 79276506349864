import { Component, Input, OnInit } from '@angular/core';
import { SubMenuItem } from '../../../../models/menu.model';
import { MenuService } from '../../../services/menu/menu.service';

@Component({
    selector: 'app-sidebar-submenu',
    templateUrl: './sidebar-submenu.component.html',
    styleUrls: ['./sidebar-submenu.component.scss'],
})
export class SidebarSubmenuComponent implements OnInit {
  @Input() public submenu = <SubMenuItem>{};

  constructor(public menuService: MenuService) {}

  ngOnInit(): void {
    this.expandMenu(this.submenu);
  }
  
  private expandMenu(menu: any) {
    menu.expanded = true;
    if (menu.children) {
      menu.children.forEach((child: any) => this.expandMenu(child));
    }
  }
  
  private collapse(items: Array<any>) {
    items.forEach((item) => {
      item.expanded = false;
      if (item.children) this.collapse(item.children);
    });
  }  
}
