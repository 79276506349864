import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPipe'
})
export class StatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'pending':
        return 'Lopend';
      case 'sold_expected':
        return 'Verkocht, Wordt verwacht';
      case 'sold_delivered':
        return 'Verkocht, In behandeling';
      case 'sold_finalized':
        return 'Verkocht, afgehandeld';
      case 'traded':
        return 'Ingeruild';
      case 'not_traded':
        return 'Niet ingeruild';
      default:
        return value; // If no match, return the original value
    }
  }
}
