import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu/menu.service';
import { HeaderMenu } from '../../../constants/header-menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.initializeHeaderMenu(HeaderMenu.pages);
   }

  public toggleMobileMenu(): void {
    this.menuService.showMobileMenu = true;
  }
}