import { Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'dashboard', loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'bedrijfsinformatie', loadChildren: () => import('../bedrijfsinformatie/bedrijfsinformatie.module').then(m => m.BedrijfsinformatieModule) },
      // { path: 'adresboek', loadChildren: () => import('../adresboek/adresboek.module').then(m => m.AdresboekModule) },
      { path: '**', redirectTo: 'error/404' }, // Handle invalid routes
    ]
  },
];
