import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../services/menu/menu.service';
import { SubMenuItem } from '../../../../models/menu.model';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
  @Output() menuItemPressed: EventEmitter<SubMenuItem> = new EventEmitter<SubMenuItem>();

  constructor(
    public menuService: MenuService,
    private router: Router,
  ) { }

  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }

  public navigateTo(item: SubMenuItem) {
    // Emitting the event when a menu item is pressed
    this.menuItemPressed.emit(item);

    if (!item.isModal) {
      this.router.navigate([item.route]);
    }
  }
}
