<div class="flex justify-between items-center">
  <div class="flex items-center">
    <img src="../../../../../assets/images/logo-text.png" alt="Autotaxatie Partners" class="m-2 mr-12 h-16">

    <div class="dropdown relative inline-block" *ngFor="let menu of menuService.headerMenu[0].items">
      <button (click)="navigateTo(menu.route)" [ngClass]="
           menu.active
            ? 'bg-green-500 text-white'
            : 'text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-600 '
        " class="mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium">
        <span>{{ menu.label }}</span>
      </button>
    </div>
  </div>

  <div class="flex flex-col items-end text-primary-500 text-sm" *ngIf="authUserInformation !== null">
    <span>{{authUserInformation.Company.name}}</span>
    <span>{{authUserInformation.Person.firstname}} {{authUserInformation.Person.infix}} {{authUserInformation.Person.lastName}}</span>
    <span class="text-green-500" (click)="logout()">Uitloggen</span>
  </div>
</div>